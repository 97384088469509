<template>
  <v-row class="mb-9 align-center" flat>
    <v-col v-if="title" height cols="12" sm="4" class="text-h6">
      <v-skeleton-loader
        :loading="loading"
        transition="scale-transition"
        type="heading"
        class=" d-flex align-center"
        width="100%"
      >
        <div class="d-flex align-end">
          <slot name="back-buttons"></slot>
          <h3>{{ title }}</h3>
        </div>
      </v-skeleton-loader>
    </v-col>

    <v-col class="d-flex  align-center justify-end" cols="12" sm="8">
      <slot name="buttons"></slot>
      <div
        :loading="loading"
        transition="scale-transition"
        type="text"
        width="100%"
        class="ml-3 mx-1 d-flex align-center"
      >
        <v-text-field
          v-if="value != undefined"
          full-width
          label="Поиск"
          hide-details
          outlined
          ref="searchInput"
          color="secondary"
          dense
          :class="{ 'v-input__closed': isSearchInputClosed }"
          @click:prepend="onMagnifyClick($event)"
          v-model="search"
          prepend-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <v-skeleton-loader
        :loading="loading"
        transition="scale-transition"
        type="text"
        width="100%"
        class="mx-1 d-flex align-center"
      >
        <div class="alerts" v-click-outside="outsideClickHandler">
          <span class="alerts__button" @click="showAlertsList">
            <v-badge
              :content="alerts"
              avatar
              bordered
              dot
              overlap
              top
              small
              color="primary"
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </span>
          <v-list
            v-if="alertsListActive"
            ref="alertsListRef"
            three-line
            class="alerts__list elevation-2 rounded"
          >
            <template v-for="alert in alerts">
              <div :key="alert.id">
                <v-list-item
                  @click="$emit('alertWasRead', alert.id)"
                  :class="{ unread: !alert.read }"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ alert.text }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      alert.created
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </template>
          </v-list>
        </div>
      </v-skeleton-loader>
      <v-skeleton-loader
        :loading="loading"
        transition="scale-transition"
        type="list-item-avatar-two-line"
        width="100%"
        class="mx-1"
      >
        <user-info class="mx-1"></user-info>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable */
import userInfo from "./UserInfo";

export default {
  components: {
    userInfo
  },
  props: {
    value: {
      // v-model === search
      type: String,
      required: false,
      default: undefined
    },
    title: {
      type: String,
      required: false,
      default: undefined
    },
    alertsListActive: {
      type: Boolean,
      required: false,
      default: false
    },
    alerts: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      isSearchInputClosed: true
    };
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  methods:{
    onMagnifyClick() {
      this.isSearchInputClosed = !this.isSearchInputClosed;
      if (!this.isSearchInputClosed) {
        this.$refs.searchInput.focus();
      } else {
        this.$emit('input', '');
      }
    },
    showAlertsList() {
      this.$emit('onToggleAlertsList');
    },
    outsideClickHandler() {
      this.$emit('onClickOutside');
    }
  }
};
</script>

<style lang="scss">
.v-input__closed .v-input__control{
  width: 0;
  opacity: 0;
}
.v-input__control{
  transition: all 0.3s;

}

.alerts {
  position: relative;
}

.alerts__button {
  cursor: pointer;
}

.alerts__list {
  position: absolute;
  top: 30px;
  left: -157px;
  z-index: 2;
  max-height: 700px;
  overflow-y: scroll;

  .unread {
    cursor: pointer;
    background-color: rgba(227, 227, 227, 0.4);

    .v-list-item__title {
      font-weight: 700;
    }
  }
}
</style>
